.section {
   align-items: center;
   display: flex;
   justify-content: space-between;
   margin: 10px 0px;
   padding: 0 40px;
}

.section-title {
   font-size: 16px;
   font-weight: 300;
   text-align: initial;
}

.section-img {
   display: none;
}

.section-description {
   align-items: center;
   display: flex;
   flex-direction: column;
   margin: 40px 40px;
   text-align: justify;
   white-space: pre-line;
}

@media (min-width: 700px) {
   .section {
      height: 48px;
      padding: 0 100px;
   }

   .section-title {
      align-items: center;
      display: flex;
      font-size: 30px;
   }

   .section-img {
      display: initial;
   }

   .section-description {
      flex-direction: row;
      margin: 35px 100px;
   }
}

@media (max-width: 800px) {
   .section {
      padding: 0 70px;
   }
}