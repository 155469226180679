.service {
   margin: 8vh 0 15vh 0;
}

.service-content {
   padding: 0 10%;
}

.service-sections {
   padding: 0;
}

.service-team {
   padding: 0 10%;
}

.service .description-title-text {
   width: 100%;
}

.service__expandable {
   display: inline-block;
}

.service .description-title-text img {
   width: 70px;
}

.service-sections {
   margin-top: 80px;
}

.service .contact {
   height: 100%;
   padding: 4vh 12vw;
}

.service-line {
   margin: 0;
}

@media (min-width: 780px) {
   .service {
      font-size: 15px;
   }

   .service__expandable {
      display: none;
   }

   .service-content {
      padding: 0 19%;
   }

   .service-sections {
      padding: 0 19%;
   }

   .service-team {
      padding: 0;
   }

   .service-cases {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
   }

   .service .description-title-text {
      width: auto;
   }

   .service .description-title-text img {
      width: 80px;
   }

   .service-members {
      display: flex;
      justify-content: center;
   }

   .service-line {
      margin: 0 19%;
   }
}

@media (min-width: 780px) and (max-width: 1230px) {
   .service-content {
      padding: 0 15%;
   }

   .service-sections {
      padding: 0 15%;
   }

   .service-line {
      margin: 0 15%;
   }
}