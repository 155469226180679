.banner {
   display: flex;
   flex-direction: column;
   height: 93vh;
   justify-content: center;
}

.banner-logo {
   margin: 0 auto;
   width: 180px;
}

.banner-title {
   align-items: center;
   color: white;
   display: flex;
   font-size: 35px;
   font-weight: 300;
   justify-content: center;
}

.banner-title-icon {
   margin-right: 5px;
   width: 60px;
}

@media (min-width: 700px) {
   .banner-logo {
      width: 350px;
   }

   .banner-title {
      font-size: 65px;
   }

   .banner-title-icon {
      width: 115px;
   }
}