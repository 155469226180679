.member {
  margin: 30px 40px;
}

.member-image-wrapper {
   margin: 9% 20%;
}

.member-image {
   border: 3px solid;
   border-radius: 50%;
   padding: 3px;
   width: 100%;
}

.member-name {
   border-top: 3px solid;
   font-size: 20px;
   font-weight: 300;
   margin: 0 13%;
   padding: 15px 0px 0;
}

.member-profession {
   font-size: 16px;
   font-weight: 300;
   margin: 0;
}

@media (min-width: 650px) and (max-width: 800px) {
   .member {
      margin: 5% 25%;
   }

   .member-image-wrapper {
      margin: 10px auto;
      max-width: 150px;
   }
}

@media (min-width: 800px) {
   .member {
      margin: 20px 30px;
   }

   .member-name {
      margin: 0 0 5px;
   }

   .member-image-wrapper {
      margin: 10px auto;
      max-width: 150px;
   }
}