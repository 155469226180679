#footer {
   align-items: center;
   background: #50007E;
   bottom: 0;
   display: flex;
   height: 8vh;
   justify-content: center;
   width: 100%;
}

.footer-logo {
   width: 60px;
}

@media (min-width: 640px) {
   .footer-logo {
      width: 80px;
   }
}

@media (min-width: 1400px) {
   .footer-logo {
      width: 90px;
   }
}