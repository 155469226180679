#header {
   align-items: center;
   background: #50007E;
   color: white;
   display: flex;
   flex-direction: row-reverse;
   height: 8vh;
   justify-content: space-between;
   position: fixed;
   top: 0;
   width: 100%;
}

.header-title {
   align-items: center;
   display: flex;
}

.header-title-arrow {
   display: none;
}

.header-main-menu-items {
   display: none;
}

.header-hamburger {
   filter: invert(1);
   margin: 20px;
}

.header-hamburger-menu {
   background-color: white;
   height: 90vh;
   left: 0;
   position: absolute;
   top: 9vh;
   width: 42vw;
}

.header-hamburger--primary-link {
   font-size: 15px;
   margin-left: 20px;
   padding-left: 5px;
   text-align: initial;
}

.header-hamburger--footer {
   bottom: 15%;
   font-size: 12px;
   margin-left: 20px;
   position: absolute;
   text-align: initial;
}

.header-title-text {
   cursor: pointer;
   font-size: 20px;
   font-weight: 300;
   margin: 20px;
}

.header-links {
   display: none;
}

@media (min-width: 650px) and (max-width: 850px) {
   .header-hamburger-menu {
      width: 25vw;
   }

   .header-hamburger--primary-link {
      font-size: 18px;
   }

   .header-hamburger--footer {
      font-size: 15px;
   }
}

@media (min-width: 800px) {
   #header {
      color: white;
      flex-direction: initial;
      position: fixed;
      top: 0;
      width: 100%;
   }

   .header-title {
      margin-left: 6%;
   }
   
   .header-title-text {
      font-size: 30px;
      font-weight: normal;
   }

   .header-title-arrow {
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
   }

   .header-main-menu-items {
      display: flex;
      flex-direction: row;
      padding-left: 7%;
      position: absolute;
      text-align: initial;
      top: 8.5vh;
      width: 100vw;
   }

   .header-main-menu-item {
      color: white;
      font-size: 20px;
      padding: 15px 20px 15px 0px;
      text-decoration: none;
   }

   .header-hamburger {
      display: none;
   }
   
   .header-links {
      display: flex;
      font-size: 16px;
      margin-right: 6%;
   }
   
   .header-link-item {
      cursor: pointer;
      font-weight: normal;
      margin: 10px 15px;
      text-decoration: none;
   }
   
   .header-link-item:hover {
      text-decoration: underline;
   }
}

@media (min-width: 1500px) {
   .header-links {
      display: flex;
      font-size: 25px;
      padding-right: 20px;
   }

   .header-main-menu-item {
      font-size: 25px;
   }
}