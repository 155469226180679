.services {
   font-size: 13px;
   padding: 0 15%;
}

.services-image {
   border-bottom: 3px solid;
   width: 35%;
}

.services-wrapper {
   display: flex;
   flex-flow: column;
   justify-content: center;
   margin-bottom: 60px;
}

.services-item {
   margin: 25px 20px 15px;
   width: auto;
}

.services-item-text {
   text-align: justify;
   min-height: auto;
}

.services-button {
   display: flex;
   justify-content: center;
   padding: 4px;
}

.services-button-text {
   font-size: 18px;
   margin: 0;
}

@media (min-width: 660px) and (max-width: 1000px) {
   .services-item {
      width: 37%;
   }

   .services-wrapper {
      flex-flow: wrap;
   }

   .services-item-text {
      min-height: 120px;
   }

   .services-button-text {
      font-size: 19px;
   }
}

@media (min-width: 1000px) {
   .services {
      font-size: 15px;
      min-height: 81vh;
      padding: 0 15%;
   }

   .services-wrapper {
      flex-flow: wrap;
   }
   
   .services-item {
      margin: 0 20px 20px;
      width: 28%;
   }

   .services-item-text {
      min-height: 125px;
   }

   .services-button-text {
      font-size: 20px;
   }
}

@media (min-width: 1400px) {
   .services {
      padding: 0 20%;
   }

   .services-item {
      margin: 0 30px 20px;
   }

   .services-item-text {
      min-height: 100px;
   }
}