
.description {
   margin-top: 10vh;
}

.description-title {
   display: flex;
   justify-content: center;
}

.description-title-text {
   align-items: baseline;
   display: flex;
   font-size: 25px;
   font-weight: 300;
   justify-content: center;
   padding: 0;
   width: 100%;
}

.description-text {
   text-align: justify;
}

@media (max-width: 340px) {
   .description-title-text {
      font-size: 25px;
   }
}

@media (min-width: 700px) {
   .description {
      margin-top: 11vh;
   }

   .description-title-text {
      font-size: 40px;
      padding: 0px 10px;
      width: fit-content;
   }
}