.case-wrapper {
   font-weight: normal;
   margin: 15% 2%;
}

.case-description {
   border-top: 2px solid;
   padding-top: 15px;
   text-align: initial;
   white-space: pre-wrap;
}

.case-name, .case-profession {
   font-weight: normal;
   margin: 0;
   text-align: center;
}

.case-quote {
   font-weight: bold;
   text-align: initial;
}

@media (min-width: 650px) and (max-width: 850px) {
   .case-wrapper {
      margin: 5% 15%;
   }
}

@media (min-width: 780px) {
   .case-wrapper {
      margin: 5% 0;
      width: 30%;
   }

   .case-wrapper-header {
      align-items: center;
      display: flex;
   }

   .case-name, .case-profession {
      text-align: initial;
   }

   .case-genre-icon {
      display: flex;
      justify-content: flex-end;
      margin-top: -2%;
   }

   .case-profession {
      text-align: initial;
   }
}