.contact {
   display: flex;
   flex-direction: column;
   height: 37vh;
   padding: 28vh 15vw;
}

.contact-logo {
   background-repeat: no-repeat;
   background-size: contain;
   height: 100%;
   max-height: 200px;
   min-height: 190px;
   width: 100%;
}

.contact-logo-img {
   background-image: url('../../assets/contactNameMob.svg');
}

.contact-logo-img-NUTRITION {
   background-image: url('../../assets/contactNameMobNutrition.svg');
}

.contact-logo-img-DEVELOPMENT {
   background-image: url('../../assets/contactNameMobDevelopement.svg');
}

.contact-logo-img-CONSULTING {
   background-image: url('../../assets/contactNameMobConsulting.svg');
}

.contact-logo-img-SPORT {
   background-image: url('../../assets/contactNameMobSport.svg');
}

.contact-logo-img-ART {
   background-image: url('../../assets/contactNameMobArt.svg');
}

.contact-data {
   text-align: justify;
}

.contact-data-name {
   margin: auto;
   width: 100%;
}

.contact-data-info {
   align-items: center;
   display: flex;
   font-size: 20px;
}

.contact-data-info-icon {
   margin-right: 8px;
   width: 33px;
}

@media (min-width: 650px) {
   .contact {
      height: 50vh;
      padding: 21vh 15vw;
   }

   .contact-logo {
      background-position: center;
      height: 45%;
      max-height: auto;
      min-height: 210px;
      width: 95%;
   }

   .contact-logo-img {
      background-image: url('../../assets/contactNameDkt.svg');
   }

   .contact-logo-img-NUTRITION {
      background-image: url('../../assets/contactNameDktNutrition.svg');
   }

   .contact-logo-img-CONSULTING {
      background-image: url('../../assets/contactNameDktConsulting.svg');
   }

   .contact-logo-img-DEVELOPMENT {
      background-image: url('../../assets/contactNameDktDevelopement.svg');
   }

   .contact-logo-img-SPORT {
      background-image: url('../../assets/contactNameDktSport.svg');
   }

   .contact-logo-img-ART {
      background-image: url('../../assets/contactNameDktArt.svg');
   }

   .contact-data {
      font-size: 19px;
      margin-left: 23%;
      margin-top: 0px;
   }

   .contact-data-info {
      font-size: 23px;
   }

   .contact-data-info-icon {
      width: 40px;
   }
}

@media (min-width: 1100px) {
   .contact-logo {
      width: 100%;
   }

   .contact-data {
      margin-left: 33%;
      margin-top: 30px;
   }

   .contact-data-info {
      font-size: 25px;
   }
}

@media (min-width: 1300px) {
   .contact-data {
      margin-left: 36%;
   }

   .contact-data-info-icon {
      width: 50px;
   }
}