#root {
   font-family: 'KumbhSans-Light', 'Kumbh Sans', sans-serif;
   font-weight: normal;
   font-style: normal;
}

#home {
   display: flex;
   flex-direction: column;
   height: 84vh;
   justify-content: center;
   padding: 0 10%;
   margin-top: 8vh;
}

.home-service {
   align-items: center;
   color: white;
   display: flex;
   height: 40px;
   margin: 5px 0;
}

.home-service-text {
   font-family: 'KumbhSans-Light', 'Kumbh Sans', sans-serif;
   font-size: 25px;
   font-style: normal;
   font-weight: normal;
   margin: 10px;
   padding: 0 20px;
   text-align: initial;
}

@media (max-width: 350px) {
   .home-service-text {
      font-size: 20px;
   }
}

@media (min-width: 700px) {
   #home {
      align-items: center;
      display: flex;
      padding: 0 25%;
   }

   .home-service {
      align-items: center;
      display: flex;
      height: 55px;
      margin: 10px 0;
      width: 498px;
      padding-left: 10%;
   }

   .home-service-text {
      font-size: 30px;
   }
}
