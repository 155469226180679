.about-view {
   font-size: 13px;
   padding: 0 13%;
}

.about-images1, .about-images2 {
   background-repeat: no-repeat;
   background-position: center;
   background-size: contain;
   height: 60px;
   width: 100%;
}

.about-images1 {
   background-image: url('../../assets/about1mob.svg');
   height: 150px;
}

.about-images2 {
   background-image: url('../../assets/about2mob.svg');
   height: 80px;
}

.about-section {
   text-align: justify;
   white-space: pre-line;
}

.about-profile {
   margin: 20px;
}

.about-profile-img-wrapper {
   border-bottom: 3px solid;
   padding-bottom: 10px;
}

.about-profile-img-src {
   background-image: url('../../assets/alexServices.png');
   background-size: cover;
   border: 3px solid;
   border-radius: 50%;
   height: 115px;
   margin: auto;
   width: 115px;
}

.about-profile-name {
   font-size: 20px;
   font-weight: lighter;
   margin-bottom: 0;
}


.about-profile-profession {
   color: #e6add5;
   margin-top: 0;
}

.about-profile-description {
   font-size: 12px;
   text-align: justify;
}

.about-philosophy {
   margin: 30px 0 60px;
}

.about-philosophy-title {
   border-bottom: 3px solid;
   font-weight: lighter;
}

.about-philosophy-description {
   text-align: justify;
   margin-top: 40px;
   white-space: pre-line;
}

@media (min-width: 700px) {
   .about-view {
      font-size: 15px;
      padding: 0 15%;
   }

   .about {
      display: flex;
   }

   .about-images1, .about-images2 {
      background-position: initial;
      background-size: contain;
   }

   .about-images1 {
      background-image: url('../../assets/about1std.svg');
      height: 70px;
   }
   
   .about-images2 {
      background-image: url('../../assets/about2std.svg');
      background-size: contain;
      height: 50px;
   }

   .about-profile {
      margin: 0 0 0 60px;
   }

   .about-profile-img-src {
      height: 140px;
      width: 140px;
   }

   .about-philosophy-title {
      margin: auto;
      width: 280px;
   }
}

@media (min-width: 1200px) {
   .about-images2 {
      height: 60px;
   }
}